import axios from 'axios';
import * as React from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import {theme} from '@partssourceinc/react-ui-core';

import {MultiGrid, RequesterViewType, CardActionType} from 'components/MultiGrid';
import PageMetaData from 'components/PageMetaData';
import * as DashboardStore from 'stores/Dashboard';
import {getCookie, getLocationQuery} from 'utility';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

const Note = styled.div`
    display: flex;
    background-color: ${theme.grey6};
    height: 100%;
    padding: 10px 20px 10px 20px;
    margin: 20px 0px 20px 0px;

    p {
        margin-bottom: 0px;
        font-weight: normal;
        color: #000000;

        a {
            cursor: pointer;
            color: ${theme.blue};
            text-decoration: none;
        }
    }

    @media (max-width: ${theme.screenMDMin}) {
        display: none;
    }
`;

@withRouter
    @connect(state => ({user: state.user,dashboard: state.dashboard}), DashboardStore.actionCreators)
export default class Repairs extends React.Component {
    static propTypes = {
        ...ReactRouterPropTypes,
        ...DashboardStore.ActionShape,
        dashboard: DashboardStore.StateShape,
    };

    constructor(props) {
        super(props);
        const gridName = 'repairsColumns'
        this.handleSelection = this.handleSelection.bind(this);
        const cachedViewType = getCookie(`requesterViewType-${gridName}`)
        const {dashboard: {myAccountFilter}} = props;
        this.state = {
            loading: false,
            page: 1,
            pageSize: 20,
            records: [],
            gridName,
            columns: [],
            currentTab: '',
            view: 'Grid',
            requesterViewType: cachedViewType ? RequesterViewType[cachedViewType] : myAccountFilter.lineItemIds.length > 0 ? RequesterViewType.AllRequesters : RequesterViewType.CurrentUser,
        };
    }

    componentDidMount() {
        const {location} = this.props;
        const {gridName} = this.state;
        const urlParams = getLocationQuery(location);
        const currentTab = (urlParams && urlParams.tab) || null;

        if (currentTab) {
            this.setState({currentTab: currentTab});
        }
        
        axios
            .get(`/ShoppingService/api/v1/account/gridSetting/${gridName}`)
            .then(x => {
                this.setState({columns: x.data.columns, view: x.data.view});
            });
        this.loadRepairs();
    }

    componentWillReceiveProps(newProps) {
        const {location} = this.props;
        if (newProps.location.pathname !== location.pathname) {
            this.loadRepairs();
        }
    }

    loadRepairs() {
        const {requesterViewType} = this.state;
        const {dashboard: {myAccountFilter}, resetMyAccountFilter, user: {settings: {showAllRepairs}}} = this.props;
        const showAllOrders = requesterViewType === RequesterViewType.AllRequesters;
        this.setState({loading: true});
        let request = {
            showAllOrders: showAllOrders,
            lineItemIds: myAccountFilter.lineItemIds,
        };
        axios.post('/ShoppingService/api/v1/repairs', request).then(response => {
            const {data: {totalRecords, records}} = response;
            if (myAccountFilter.lineItemIds) resetMyAccountFilter();
            this.setState({
                records: records,
                totalRecords: totalRecords,
                loading: false,
            });
        });
    }

    handleSelection(item) {
        const {history} = this.props;
        history.push(`/order/` + item.lineItemId);
    }

    handleRequesterToggle(requesterViewType) {
        this.setState({requesterViewType: requesterViewType}, this.loadRepairs);
    }

    handleBundleCandidates(lineItemId) {
        const {history} = this.props;
        history.push(
            {
                pathname: '/orders/packingSlip',
                state: {
                    lineItemId: lineItemId,
                },
            }
        );
    }

    render() {
        const {
            gridName,
            columns,
            records,
            view,
            loading,
            requesterViewType,
            currentTab,
        } = this.state;
        const gridKeys = [
            {
                id: 'initiated',
                fieldName: 'groupName',
                value: 'Initiated',
                tabDisplay: 'Initiated',
                cardAction: CardActionType.Repair,
                height: '520',
            },
            {
                id: 'shippedOutbound',
                fieldName: 'groupName',
                value: 'ShippedOutbound',
                tabDisplay: 'Shipped Outbound',
                cardAction: CardActionType.Repair,
                height: '380',
            },
            {
                id: 'evaluating',
                fieldName: 'groupName',
                value: 'Evaluating',
                tabDisplay: 'Evaluating',
                cardAction: CardActionType.Repair,
                height: '380',
            },
            {
                id: 'quoted',
                fieldName: 'groupName',
                value: 'Quoted',
                tabDisplay: 'Quoted',
                cardAction: CardActionType.Quote,
                height: '380',
            },
            {
                id: 'repairing',
                fieldName: 'groupName',
                value: 'Repairing',
                tabDisplay: 'Repairing',
                cardAction: CardActionType.Repair,
                height: '380',
            },
            {
                id: 'shippedInbound',
                fieldName: 'groupName',
                value: 'ShippedInbound',
                tabDisplay: 'Shipped Inbound',
                cardAction: CardActionType.Repair,
                height: '560',
            },
            {
                id: 'delivered',
                fieldName: 'groupName',
                value: 'Delivered',
                tabDisplay: 'Delivered',
                cardAction: CardActionType.Repair,
                height: '560',
            },
        ];

        return (
            <div>
                <PageMetaData
                    title="Depot Repairs"
                    pageType="other"
                    trackAnalytics={true}
                />
                <MultiGrid
                    onRequesterToggle={::this.handleRequesterToggle}
                    showRequesterToggle={true}
                    requesterViewType={requesterViewType}
                    gridName={gridName}
                    view={view}
                    gridKeys={gridKeys}
                    data={records}
                    columns={columns}
                    label="My Account"
                    subLabel="Depot Repairs & Quotes"
                    onSelect={this.handleSelection}
                    onHandleBundleCandidates={::this.handleBundleCandidates}
                    loadingData={loading}
                    headerLink={{text: 'View All Repairs', url: '/orders/history'}}
                    onTabClick={(tab) => this.setState({currentTab: tab})}
                    onRefresh={() => this.loadRepairs()}>
                    {currentTab === 'delivered' && <Note>
                        <p>
                            Recently delivered orders remain on the Delivered tab for 30 days.
                            To review older orders, &nbsp;
                            <NavLink to="/orders/history">
                                click here
                            </NavLink>
                        </p>
                    </Note>}
                </MultiGrid>
            </div>
        );
    }
}
